import {
  Image,
  notDesktop,
  styled,
  useIsClient,
  useIsMobile,
} from "@obvio/app";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

import type { ImageAsset } from "@obvio/app";
import type { MotionValue } from "framer-motion";
import type { ReactElement } from "react";

type ImagesFloatProps = {
  images: ImageAsset[];
  custom?: boolean;
};

const Wrap = styled.div`
  width: 100%;
  height: 60vh;

  margin-bottom: ${(theme) => theme.spacing.extraLarge};

  @media ${notDesktop} {
    height: 90vh;
  }
`;

const ImgWrap = motion(styled.div<{
  $x: number;
  $y: number;
  $scale: number;
  $ratio: number;
}>`
  position: absolute;
  left: ${(_, { $x }) => $x}%;
  width: ${(_, { $scale }) => 20 * $scale}vw;
  top: calc(20% + ${(_, { $y }) => $y}%);
  transform: translateY(-50%);
  aspect-ratio: ${(_, { $ratio }) => $ratio};
  @media ${notDesktop} {
    width: ${(_, { $scale }) => 55 * $scale}vw;
  }
`);

type Place = {
  x: number;
  y: number;
  scale: number;
};

type AbsoluteImageProps = {
  place: Place;
  image: ImageAsset;
  scrollYProgress: MotionValue<number>;
  odd: boolean;
  custom?: boolean;
};
function AbsoluteImage({
  place,
  scrollYProgress,
  image,
  odd,
  custom,
}: AbsoluteImageProps): ReactElement | null {
  const isClient = useIsClient();
  const transformed = useTransform(
    scrollYProgress,
    [0, 1],
    ["0rem", odd ? `-10vh` : `10vh`],
  );
  if (!isClient) {
    return null;
  }
  return (
    <ImgWrap
      $x={place.x}
      $y={place.y}
      $ratio={image.ratio}
      $scale={place.scale}
      style={{
        y: transformed,
      }}
    >
      {custom ? (
        <img
          src={image.name}
          alt={image.alt}
          style={{ width: "100%", height: "100%" }}
        />
      ) : (
        <Image img={image} />
      )}
    </ImgWrap>
  );
}

// capped to 10
const places: Place[] = [
  { y: 2, x: 0, scale: 0.7 },
  { y: -10, x: 11, scale: 1 },
  { y: 10, x: 22, scale: 0.5 },
  { y: -5, x: 36, scale: 0.3 },
  { y: 0, x: 40, scale: 1 },
  { y: 10, x: 46, scale: 0.3 },
  { y: 5, x: 59, scale: 0.8 },
  { y: -5, x: 70, scale: 0.1 },
  { y: 4, x: 72, scale: 0.4 },
  { y: -10, x: 82, scale: 1 },
];

const placesMobile: Place[] = [
  { y: -10, x: 2, scale: 0.7 },
  { y: 5, x: 65, scale: 0.56 },
  { y: -7, x: 36, scale: 1 },
  { y: 1, x: 47, scale: 0.4 },
  { y: 58, x: 11, scale: 0.5 },
  { y: 28, x: 51, scale: 0.79 },
  { y: 29, x: 5, scale: 0.8 },
  { y: 58, x: 38, scale: 0.3 },
  { y: 20, x: 12, scale: 0.4 },
  { y: 30, x: 55, scale: 0.75 },
];

export function ImagesFloat({
  images,
  custom,
}: ImagesFloatProps): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "2 1"],
  });

  return (
    <Wrap ref={ref}>
      {images.slice(0, 10).map((image, idx) => (
        <AbsoluteImage
          scrollYProgress={scrollYProgress}
          odd={idx % 2 === 0}
          place={isMobile ? placesMobile[idx] : places[idx]}
          image={image}
          custom={Boolean(custom)}
          // eslint-disable-next-line react/no-array-index-key
          key={String(idx)}
        />
      ))}
    </Wrap>
  );
}
